.leftSide {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: rgba(10, 31, 143, 1) !important;
}
.contactDetails {
  display: flex;

  align-items: center;
}
.contactDetails2 {
  display: flex;

  flex-direction: column;
}
.info_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.whiteTextTypography {
  color: #fff !important;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.whiteTextTypography > a {
  color: #fff !important;
}
.myFormBox {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.myFormBoxaggree {
  display: flex;

  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0px;
}
.mySelectBox {
  display: flex;
  width: 100%;
  padding: 10px;
  border: 1px solid #464646 !important;
  border-bottom-left-radius: 1.75rem;
 
  outline: #464646;
}
.myNameBox {
  display: flex;
  width: 100%;
  padding: 10px;
  border: 0px;
  background-color: #fff;
  border-bottom: 1px solid #464646;
  outline: none;
  border: 1px solid #fff !important;
  border-bottom: 1px solid #464646 !important;
}
.myMessageBox {
  display: flex;
  width: 100%;
  padding: 10px;
  border: 1px solid #464646;
  background-color: #f0f0f0;
  outline: #464646;
}
.formFieldLegend {
  color: #464646;
  display: block;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
}
.formChoice {
  cursor: pointer;
  display: block;
  line-height: 1;
  padding-left: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.5rem;
  font-weight: 300;
  color: #464646;
}
.formFieldSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: #464646;
  line-height: 1.5;
  min-height: 3rem;
  outline: none !important;
  overflow: hidden;
  padding: 0.75rem 2rem 0.75rem 1rem;
  text-overflow: ellipsis;
  width: 100%;
  border: 1px solid #464646;
  border-bottom-left-radius: 1.75rem;
  background-color: #f0f0f0;
  outline: #464646;
}
.formButton {
  align-items: center;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  border-bottom-left-radius: 1.75rem;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  max-width: 100%;
  min-height: 3rem;
  max-width: 300px;
  overflow: hidden;
  padding: 0.75rem 1.5rem;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s;
  vertical-align: middle;
}
textarea.formTextArea {
  border: 1px solid #464646;
  line-height: 1.4;
  padding: 0.75rem 1.5rem;
  background-color: transparent;
  color: #464646;
  width: 100%;
  min-height: 120px;
}
