#ag-canvas {
  height: 100%;
}

.ag-item :first-child {
  border-radius: 0px !important;
}
.ag-item2 {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 0px;
  border: 2px #00B6ED solid;
  color : white !important;
  /* background: url('../../assets/images/avatar.png') center no-repeat; */
  height: 100%;
  width: 100%;
  position: relative;
}
.myPartName{
  background-color: rgba(0,0,0,0);
  border:0px;
  width: 150px;
  border: none;
  bottom : 0px;
  color: #fff;
 position: absolute;
 z-index: 99999;
}

.ag-item {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 0px;
  border: 2px #00B6ED solid;
  color : black !important;
  /* background: url('../../assets/images/avatar.png') center no-repeat; */
  height: 100%;
  width: 100%;
  position: relative;
}

#ag-resize-container {
  background-image: none!important;
  background-color: black;
  display: flex;
  justify-content: center;
  height: center;
}

#ag-resize-container .ag-item {
  border: none!important;
}




.ag-btn-group {
  height: 45px;
  width: calc(100% - 60px);
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  background: transparent;
  z-index: 11;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ag-btn-group:hover,
.ag-btn-group.active {
  background: rgba(18, 74, 99, .8);
}

.ag-btn {
  z-index: 12;
  opacity: 0;
  color: white;
  cursor: pointer;
  font-size: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ag-btn:hover {
  color: #00B6ED;
}

.ag-btn.disabled,
.ag-btn.disabled:hover {
  color: #637C8E;
  cursor: not-allowed;
}

.ag-btn-group:hover .ag-btn,
.ag-btn-group.active .ag-btn {
  opacity: 1;
}

.ag-btn.off.videoControlBtn .ag-icon-camera {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera-off {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera {
  display: inline-block;
}

.ag-btn.off.videoControlBtn .ag-icon-camera-off {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic-off {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic-off {
  display: inline-block;
}