.schedule {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}
.dirCla{
    color: green;
}
.indirCla{
    color : blue;
}
.modal-dialog .table tbody{
    word-break: break-all;
} 