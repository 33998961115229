.query{
    display: flex;
justify-content: space-between;
  }
  .query button{
    width: fit-content;
  }
  .query div{
    padding: .75rem 1.5rem;
  }
  .query div a{
    font-size: 18px;
padding: 0 10px;
  }