.header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  border-bottom: 2px solid #787878;
  background-color: #ffffff;

  z-index: 999;
  margin: 38px 0px 0px 0px;
  position: sticky;
  padding: 0px 22px;
  top: 0px;
}
.headerMin {
  display: flex;
  justify-content: space-between;
  width: 100%;

  border-bottom: 2px solid #787878;
  background-color: #ffffff;
  height: 80px;
  z-index: 999;
  position: sticky;
  top: 0px;
}

.heading {
  padding-bottom: 2%;
}

.form {
  width: 60%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 10%;
  background: white;
  padding: 25px;
  box-shadow: 6px 6px 10px 2px #00000075;
  border-radius: 15px;
}

.modal {
  position: absolute;
  left: 50px;
}
.logo {
  transition: 0.6s all;
  display: flex;

  max-width: 16rem;

  margin: 22px;
  width: 100%;
  height: 36px;
}
.companyLogoSpan {
  display: flex;
  align-items: center;
}
.companyLogoSpan .companyLogo {
  margin: 5px 2px;
  display: flex;
  align-items: center;
}
.companyLogo {
  transition: 0.6s all;
  display: flex;
  max-width: 8rem;
  margin: 0px 18px 10px 2px;
  width: 100%;
}
#criflogo {
  display: inline-block;
  width: 26%;
}
.linkspan {
  margin-left: 1%;
}
#criflogo img {
  display: inline-block;
  height: 30px;
  margin: 2px 18px 0px 10px !important;
}
.logomin {
  display: flex;
  max-width: 12rem;
  justify-content: center;
  align-items: center;
  transition: 0.6s all;
  width: 100%;
  margin: 22px 32px;
  height: 36px;
}

.menu {
  list-style: none;
  display: flex;
}

.FolderWrapperList .folderView svg {
  font-size: 2.5rem;
}
.fileView .filecomp span svg {
  font-size: 2.1rem !important;
}

/* .menu li {
  padding-right: 15px;
  padding: 4px 15px;
  border-radius: 25px;
  margin-right: 15px;
  background: rgb(61,71, 117);
  border: 2px solid #ffffff;
  font-size: 14px;
} */

.menu li:hover {
  box-shadow: 4px 6px 10px 1px #000000;
  transition: 0.5s ease;
  cursor: pointer;
}

.menu li a {
  color: #ffffff !important;
}

a:hover {
  text-decoration: none;
}

footer {
  background-color: #f4f4f4;
  padding: 15px;
  width: 100%;

  box-shadow: 0px -3px 10px #00000029;
  border-top: 2px solid #787878;
}

.content_register {
  width: 50%;
  margin: auto;
  text-align: center;
  padding: 25px;
  margin-top: 15%;
  margin-bottom: 15%;
}

.otp {
  width: 50%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  background: white;
  padding: 25px;
  padding: 3em 2em 3em;
  box-shadow: 6px 6px 10px 2px #00000075;
  border-radius: 15px;
}

.error {
  color: red;
  font-weight: bold;
}

.question_query {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question_query_field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar {
  padding: 0 !important;
}

.manage_file {
  -webkit-user-modify: read-write;
}

.password-icon {
  position: absolute;
  right: 35px;
  top: 38px;
  cursor: pointer;
}

.inprogress {
  color: rgb(61, 71, 117) !important;
}

.completed {
  color: rgb(61, 131, 117) !important;
}

.declined {
  color: red !important;
}

.footerCopyright {
  color: #fff !important;
}
.send {
  background-color: rgba(120, 120, 120, 0.3);
}

.received {
  background-color: #fff;
}
.swal2-styled.swal2-confirm {
  background-color: rgb(61, 71, 117) !important;
}
h1,
h2,
h3 h4,
h5,
h6,
p {
  color: #464646;
}
.form-control:focus {
  border-color: rgb(61, 71, 117) !important;
}
.btn-primary {
  background-color: rgb(61, 71, 117) !important;
  border-color: rgb(61, 71, 117) !important;
  color: #fff !important;
}
html body a {
  color: rgb(61, 71, 117);
}
.main-menu.menu-light .navigation > li > a {
  color: #464646;
}

.main-menu.menu-light .navigation > li > span {
  color: #464646;
}

.messageIcon {
  color: #0071ce;
  font-size: 1rem;
  cursor: pointer;
}
.discussionMessage {
  color: #b63c00;
  font-size: 1rem;
  cursor: pointer;
}
.discussProposal {
  color: #393470;
  font-size: 1rem;
  cursor: pointer;
}
.helpIcon {
  font-size: 2rem;
  cursor: pointer;
  float: right;
  color: #464646;
}

.viewDiscusion {
  color: #615339;
  font-size: 1rem;
  cursor: pointer;
}
.feedbackIcon {
  color: rgb(61, 71, 117) !important;
  font-size: 1rem;
  cursor: pointer;
}
.deleteIcon {
  color: orangered;
  font-size: 1rem;
  cursor: pointer;
}
.editQuery {
  color: #081f8f;
  font-size: 1rem;
  cursor: pointer;
}
.uploadIcon {
  font-size: 1rem;
  cursor: pointer;
  color: #bc363d;
}
.payment {
  color: rgb(61, 71, 117) !important;
  cursor: pointer;
}
.paymentDeclined {
  color: red !important;
  cursor: pointer;
}
.customBtnSign:hover {
  color: #fff;
}
.finalReport {
  color: red !important;
}
.draftReport {
  color: rgb(61, 131, 117) !important;
}
.customBtnSign {
  border-bottom-left-radius: 1rem;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  width: 100px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}
.downloadBtnPdf {
  border-bottom-left-radius: 1rem;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  width: 200px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
  outline: 0px;
}
.customBtnlg {
  border-bottom-left-radius: 1rem;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  margin: 50px auto;
  justify-content: center;
  line-height: 1;
  width: 150px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}
.logoutBtn {
  border-bottom-left-radius: 1rem;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  width: 150px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}

.customBtn,
.customBtnDisabled {
  border-bottom-left-radius: 1rem;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  width: 100px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}

.compbtn {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  width: 30px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
}

.navButton {
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  color: #0071ce;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  justify-content: center;
  line-height: 1;
  width: 52px;
  border-radius: 32px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.2rem 0.2rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  outline: none;
  vertical-align: middle;
}
.navButton:hover {
  background-color: #dbdbdb;
}
.navButton:focus {
  background-color: #dbdbdb;
  border: 2px solid #0071ce;
  outline: none !important;
}
.linkWhite {
  background-color: rgba(0, 0, 0, 0.01);
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  overflow: hidden;
  padding: 0.3rem 0.1rem;
  position: relative;
  text-decoration: none;
  height: 40px;
  margin: auto 3px;
  vertical-align: middle;
}
.btnWhite {
  border-bottom-left-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.01);
  border: 1px solid #fff;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  margin: 10px 0px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}
.profileBtn {
  border-bottom-left-radius: 1rem;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  width: 100px;
  height: 34px;
  overflow: hidden;
  float: right;

  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}
.noSignINBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
}

.searchBtn {
  border-bottom-left-radius: 1rem;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  width: 100px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}
.customBtn > a {
  color: #fff;
}

.customBtn:hover,
.searchBtn:hover,
.autoWidthBtn:hover,
.queryPlusIcon:hover,
.btnSearch:hover,
.helpIcon:hover .customBtn:focus,
.searchBtn:focus,
.autoWidthBtn:focus,
.queryPlusIcon:focus,
.btnSearch:focus,
.helpIcon:focus {
  background-color: #081f8f !important;
  border: 1px solid #081f8f !important;
  color: #fff !important;
  outline: 1px solid #081f8f;
}
.btnWhite:hover {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: #464646 !important;
  outline: 1px solid #fff;
}
.linkWhite:hover {
  background-color: #fff !important;
  color: #464646 !important;
  text-decoration: underline;
}
.linkWhite:hover i {
  text-decoration: none !important;
}
.signInBtn {
  border-bottom-left-radius: 1rem;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  width: auto;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}
.btnSearch {
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  width: auto;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}
.autoWidthBtn {
  border-bottom-left-radius: 1rem;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;

  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}
.dangerBtn,
.dangerBtnDisabled {
  border-bottom-left-radius: 1rem;
  background-color: orangered;
  border: 1px solid orangered;
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  width: 100px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.6rem 1rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}

.dangerBtn:hover {
  background-color: rgb(177, 52, 6) !important;
  border: 1px solid rgb(177, 52, 6) !important;
  color: #fff !important;
}
.changePassword .dropdown-item:hover {
  color: #0071ce;
}
.customBtnDisabled:disabled,
.customBtnDisabled.disabled {
  color: #fff;
  border-color: #a0a0a0;
  background-color: rgba(61, 71, 117, 0.5);
}
.customBtnDisabled:hover,
.dangerBtnDisabled:hover {
  cursor: not-allowed;
}
.dangerBtnDisabled:disabled,
.dangerBtnDisabled:disabled {
  color: #fff;
  border-color: #a0a0a0;
  background-color: rgba(212, 22, 9, 0.5);
}
.queryPlusIcon {
  background-color: #0071ce !important;
  color: #fff !important;
  font-weight: 700 !important;
}
.acceptIcon {
  color: rgb(61, 131, 117);
  cursor: pointer;
  font-size: 16px;
}
.rejectIcon {
  color: red;
  cursor: pointer;
  font-size: 16px;
}
.inboxTitle:hover {
  color: #0071ce;
}
.historyIcon {
  font-size: 16px;
  cursor: pointer;
  color: "green";
}
.notification:hover .badge {
  background-color: #0071ce !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #464646;
}
.contentTitle {
  color: #464646;
  display: flex;
  margin: 0 0 5px 0;
  justify-content: center;
}
.fixedTab {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding: 10px;
}
.myStyle2 {
  margin: 10px auto;
  color: #55425f;
  font-weight: 1000;
}
.myStyle1 {
  margin: 10px auto;
  font-size: 14px;
}
.menu-expanded .main-menu.menu-light .navigation > li > a > i {
  margin-right: 8px !important;
}
.menu-expanded .main-menu.menu-light .navigation > li > span > i {
  margin-right: 8px !important;
}
.caret {
  background: url(http://tablesorter.com/themes/blue/bg.gif) no-repeat 99;
}
th.sortable {
  position: relative;
  cursor: pointer;
}

th.sortable::after {
  font-family: FontAwesome;
  content: "\f0dc";
  position: absolute;
  right: 8px;
  color: #fff;
}

th.sortable.asc::after {
  content: "\f0d8";
}

th.sortable.desc::after {
  content: "\f0d7";
}

th.sortable:hover::after {
  color: #fff;
}
.clientSubMenu {
  display: flex;
  width: 850px;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
}
.headingContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.myLink22 {
  background-color: #fff !important;
  z-index: 0;
  border: 1px solid #fff;
}
.myFaq table {
  border: 1px solid #ccc;
}
.subLink2 {
  display: flex;
  width: 100%;
}
#flexCheckDefault {
  width: 1.2rem;
  height: 1.2rem;
}
.mainCheckBox {
  outline: 1px solid red;
  border: 0px;
}
.customInvalid {
  border: 1px solid red;
}
.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}
.ck.ck-content ol,
.ck.ck-content ol li::marker {
  list-style-type: decimal;
  padding: 0px 40px;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}
input[type="date"] {
  display: block;

  /* Solution 1 */
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;

  /* Solution 2 */
  /* min-width: 96%; */
}
.proposalModal {
  display: flex !important;
  height: 100% !important;
}
.modalManualTable {
  display: flex;
  width: 30vw;
  color: #464646;
  font-size: 18px;
  cursor: pointer;
}
.modalManualTablePage {
  display: flex;
  width: 20vw;
  color: #464646;
  font-size: 18px;
  justify-content: flex-end;
  cursor: pointer;
}
.modalTextHeading {
  font-size: 18px;
  color: #464646;
  font-weight: 700;
}
.modalText {
  font-size: 12px;
  color: #464646;
  font-weight: 400;
}
.modalText {
  font-size: 18px;
  color: #464646;
}
.myTwitter,
.instagramVideo,
.myFacebook,
.myLinkedIn {
  display: flex;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: 0.3s all;
  margin: 0 7px;
  outline: #000;
  color: #464646;
}
.myFacebook:hover {
  color: #3b5998;
}
.instagramVideo:hover {
  background: #fff;

  color: #fd5949;
}
.myLinkedIn:hover {
  color: #0077b5;
}
.myTwitter:hover {
  /* background-color: rgba(29, 161, 242, 0.2); */
  color: #55acee;
}
.imgGap {
  display: flex;
  margin: 18px 0px;
}

.querydiv {
  cursor: pointer;
}
.querydiv:hover {
  text-decoration: underline;
  /* color: #4340c2; */
}
.modsavediv {
  /* display: flex; */
  /* justify-content: flex-end; */
}
.dynform {
  border: 1px solid;
}
.heightInc {
  height: 100px !important;
}

.rightMenu {
  width: 120px;
}
/* .noPerm{
  background-color: #dadada!important;
} */
.crumbName {
  font-weight: 500;
}
.crumbName:hover {
  text-decoration: underline;
}
.crumbActive {
  color: #0071ce;
}
.crumbArrow {
  margin: 0rem 0.2rem;
}
.forBreak p {
  word-break: break-all;
}

.complianceView {
  position: absolute;
  right: 0;
  margin-right: 1rem;
}

.viewIcon {
  margin: 0px 5px;
}

/* .compLabel{

} */
/* .cardHeaderComp {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.cardHeaderComp .crumbName {
  flex-grow: 1;
}
.cardHeaderComp .ComplianceHeaderSpan {
  flex-grow: 0;
} */

@media (max-width: 767px) {
  footer {
    position: relative !important;
  }
  .contentTitle {
    color: #464646;
    display: flex;
    font-size: 0.8rem;
    margin: 0 0 5px 0;
  }
}
@media (max-width: 768px) {
  .contentTitle {
    color: #464646;
    display: flex;
    font-size: 0.8rem;
    margin: 0 0 5px 0;
  }
}

.HeadingSpan {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.noticespan {
  position: absolute;
  bottom: 0;
  left: 33%;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.PaperBoat {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.paper {
  width: 15%;
  padding: 5px;
  margin: 2rem;
  height: 12rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px!important;
}
.paperBox {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 12rem;
}
.papermark {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
}
.paperAllow {
  border-color: green;
}
.paperDenined {
  border-color: red;
}
.permPaper{
  background-color:#d0d9d0!important;
}
.noPermPaper{
  background-color:#E2DAE6!important;
}
.papermark i {
  font-size: 2rem;
  font-weight: 400;
}
.papername {
  font-size: 1.5rem;
}

.folderCreatedComp svg{
  font-size: 2.5rem;
}
.crumbArrow svg{
  font-size: 1.5rem;
}

.greenLine .ant-select-item-option-content{
  color: green!important;
}

.redLine .ant-select-item-option-content{
  color:red!important
}

.select-container .ant-select-selector {
  background-color: white;
  color: black;
}

.green-bg .ant-select-selector .ant-select-selection-item{
  color: green!important;
}

.red-bg .ant-select-selector .ant-select-selection-item{
  color: red!important;
}

.status-active {
  color: green;
  font-size: 1.5rem;
  border-radius: 4px;
  width: fit-content;
  position: absolute;
  right: 12%;
}

.status-inactive {
  color:red;
  font-size: 1.5rem;
  border-radius: 4px;
  width: fit-content;
  position: absolute;
  right: 12%;
}