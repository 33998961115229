@media (max-width: 767px) {
  .header {
    padding: 5px;
  }
  .header h5 {
    font-size: 14px;
  }
  .menu li {
    font-size: 10px;
  }
  .menu {
    margin: 0;
  }
  .logo {
    width: 50% !important;
  }
  .mw {
    width: 50%;
  }
  .content_register {
    width: 100% !important;
    margin: auto;
    text-align: center;
    padding: 0;
    font-size: 14px;
    margin-top: 15%;
  }
 
  .form {
    width: 100% !important;
    padding: 15px;
    margin-bottom: 25%;
  }
  .form h2 {
    font-size: 24px;
  }
  footer {
    position: fixed;
  }
  .otp {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
}
