.schedule {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}
.dirCla{
    color: green;
}
.indirCla{
    color : blue;
}
.modal-dialog .table tbody{
    word-break: break-all;
} 
.compField .sub span {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100px;
    margin: 8px 8px 8px 8px;
    background-color: rgba(0, 0, 0, 0);
  }