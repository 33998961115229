.table_head_Proposal th {
    font-size: 11px;
    padding: 3.5px;
  }
  
  .table_bdy_proposal td {
    font-size: 10px;
    padding: 2px;
  }
  
  .table-responsive{
    display: block;
    height: 65vh;
    overflow-x: hidden;
  }
  .card-body2 {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    height: 65vh;
    overflow: scroll;
}