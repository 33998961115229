.table_head_Proposal th {
  font-size: 11px;
  padding: 3.5px;
}

.table_bdy_proposal td {
  font-size: 10px;
  padding: 2px;
}

.table-responsive{
  display: block;
  height: 65vh;
  overflow-x : hidden;
}

.tableFixHead          { overflow: auto; }
.proposalQueryCusttableFixHead thead th {
  position: sticky;
   
   top: 0;
    z-index: 1;
     border: 0px; 
     background-color:rgb(61, 71, 117) !important;
      color : #ffffff !important;}
.rightAli {
  display:  flex;
  justify-content:  flex-end;
  border:  0px;
}
.tabHover{
  transition: all .9s;
}
.tabHover:hover{
 text-decoration: underline;
  color:#0071CE;
 
  cursor: pointer;
}
/* 

.tableFixHead          { overflow-y: auto; height: 100px; }
.tableFixHead thead th { position: sticky; top: 0; }


table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; } */
