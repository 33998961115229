.articleContent {
  display: flex;
  max-width: 1000px;
  width: 100%;
  flex-direction: column;
  min-height: 490px;
  overflow: hidden;
  margin: auto;
}
.articlesDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  min-height: 400px;
  padding: 20px;
  overflow: auto;
  margin: 10px 0;
  background-color: #fff;
}
.myLogo {
  display: flex;
  width: 100%;
  max-height: 36px;
  max-width: 100px;
  object-fit: contain;
}
.myLink {
  color: #fff;
}

.myVideo {
  display: flex;
}
