@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "Halyard";
  src: local("Halyard"), url("./Fonts/HalyardTextBook.otf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;

  color: #3f3f3f;

  background: #f0f0f0 !important;
  position: relative;
  min-height: 100vh;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
a,
button,
th,
td,
div,
span {
  font-family: "Halyard" !important;
  color: #464646;
}

.ag-theme-alpine .ag-icon {
  font-family: "agGridAlpine";
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  font-family: "agGridAlpine" !important;
}

.modal-open .modal {
  overflow: hidden !important;
  position: fixed !important;
}

#myClientModal .modal-content {
  height: 600px;
}

.myParticipantName {
  display: flex;
  border: 1px solid #ccc;
  background-color: #ccc;
  padding: 1px 10px;
  box-shadow: 4px 4px 8px #ccc;
  border-radius: 3px;
  margin: 8px 5px;
  padding: 5px;
}

.formContentWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.profileInfo {
  display: flex;
  width: 50%;
}

.profileInfo h4 {
  font-family: "Halyard";
  font-size: 14px;
  color: #464646;
  word-break: break-word;
}

#profileInfoLabel h4 {
  font-weight: 700 !important;
}

.profileInfo2 {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.main-menu.menu-fixed {
  z-index: 99;
}

/* Generated by grunt-webfont */
#myFrame2 {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch !important;
}

#login img,
#signUp2 img {
  display: flex;
  max-width: 800px;
  width: 100%;
  margin: auto;
}

/* .form-control.is-invalid {
  background-image: unset !important;
} */
.downloadManual {
  display: flex;
  margin: 10px 10px 10px auto;
}

.calendar.visible {
  top: 20% !important;
  left: 50% !important;
}

.subMenuHeader {
  text-align: left;
  padding: 0rem 1rem;
}

.headerMenu {
  font-size: 18px;
}

.updatesContent {
  font-size: 14px !important;
}

.headerWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.subPaymentPlan {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0px;
}

.spaceRadio {
  margin: 0px 5px 0px 0px;
}

.myporposalCheckBox {
  display: flex;
  max-width: 90px;
  width: 100%;
}

#payDetails table {
  line-height: 35px;
}

#payDetails table tr td {
  padding-right: 10px;
}

::-webkit-scrollbar-track {
  scroll-behavior: smooth;
}

.modal-footer {
  padding: 0.75rem 0px !important;
}

#payDetails table tr {
  display: flex;
  width: 100%;
}

#payDetails table tr td {
  display: flex;
  width: calc(100% / 2);
  border: 0.5px solid #ccc;
  padding: 5px;
}

#payDetails table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* QueryStyle css */
.tableStyle {
  display: flex;
  padding: 5px 20px !important;
}

.queryBox {
  display: block;
  height: 55vh;
  width: 100%;
  overflow-y: auto;
}

.queryBox > .table {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.queryBox > .table > thead {
  display: flex;
  width: 100%;
}

.queryBox > .table > thead > tr {
  display: flex;
  width: 100%;
  justify-content: start;
}

.queryBox > .table thead tr th:nth-child(1) {
  display: flex;
  width: 30%;
  color: #464646;
}

.queryBox > .table thead tr th:nth-child(2) {
  display: flex;
  width: 70%;
  color: #464646;
}

.queryBox > .table > tbody {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.queryBox > .table > tbody > tr {
  display: flex;
  width: 100%;
  word-break: break-word;
}

.queryBox > .table > tbody > tr > th {
  display: flex;
  flex-basis: 30%;
  border-bottom: 0px;
  color: #464646;
  font-weight: 300;
  font-size: 16px;
}

.queryBox > .table > tbody > tr > td {
  display: flex;
  flex-basis: 70%;
  flex-wrap: wrap;
  text-align: justify;
  border-bottom: 0px;
  flex-direction: column;
  padding: 11px 20px;
}

.modalBox {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  flex-direction: column;
}

.boxContainer {
  display: flex;
  display: flex;
  width: 50vw;
  height: auto;
  /* border: 2px solid greenyellow; */
  flex-direction: column;
}

.canBtn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;
  background-color: rgb(38, 45, 71);
}

.canBtn h4 {
  color: #fff;
}

.my2 {
  display: flex;
  width: 100%;
}

#myBtn {
  color: red;
}

#myBtn:hover {
  padding: 10px;
  background-color: red;
  color: white !important;
  font-weight: 900;
  font-size: 40px;
  border-radius: 50%;
}

.validationError {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.popup-content {
  margin: auto;
  background: #fff;

  padding: 5px;
  border: 1px solid #d7d7d7;
}

.customBtnCan {
  border-bottom-left-radius: 1.75rem;
  background-color: rgb(158, 164, 128);
  border: 1px solid rgb(158, 164, 128);
  color: #fff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  max-width: 100%;
  min-width: 100px;
  min-height: 3rem;
  overflow: hidden;
  padding: 0.75rem 1.5rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}

.customBtnCan:hover {
  background-color: rgba(158, 164, 128, 0.7);
  border: 1px solid rgba(158, 164, 128, 0.7);
  color: #fff !important;
}

.customBtn:hover {
  background-color: #081f8f !important;
  border: 1px solid #081f8f !important;
  color: #fff !important;
}

.StartPage {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  min-height: 750px;
  flex-wrap: wrap;
}

.StartPageDetailscontact {
  display: flex;
  width: 100%;
  margin: "15px 0px";
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 490px;
  overflow: hidden;
}

.StartPageDetails {
  display: flex;
  width: 100%;

  align-items: center;
  flex-direction: column;
  min-height: 490px;
  overflow: hidden;
}

.loading {
  display: flex;
  z-index: 99999;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.signIn {
  display: flex;
  width: 50%;
  opacity: 0.9;
  background-image: url("./assets/images/loginCover.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}

.signIn .signBtn {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
  position: relative;
}

.boxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
}

.signBtn h4 {
  color: #fff !important;
}

.customForm {
  display: flex;
  width: 90%;

  justify-content: center;
  min-height: 320px;
  height: 100%;
  flex-direction: column;
}

.customForm2 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 320px;
  height: 100%;
  flex-direction: column;
}

.signInForm {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10px 28px;
}

.passForm {
  line-height: 10px;
  margin: 1px 0px;
}

.signUpLogged {
  display: flex;
  flex-direction: column;
  padding: 10px 28px;
  width: 50%;

  justify-content: center;

  background-color: #5a6a6b;
}

.signUp {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: #5a6a6b;
}

.password-icon-login {
  position: relative;
  left: 285px;
  top: -26px;
  cursor: pointer;
}

.SignUpLink {
  color: #fff !important;
}

.signUp button {
  display: flex;
}

.signUp p {
  margin: 0px 25px;
}

.signUp .declinedmsg {
  color: red !important;
  font-weight: 400;
  text-align: left;
  font-size: 15px;
}

.btn-success {
  background-color: #438375 !important;
}

.form-label {
  color: #464646;
}

.nav-tabs .nav-item a {
  color: #464646 !important;
}

.updatesLink {
  color: rgb(61, 71, 117);
}

.loginHeading {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  flex-wrap: wrap;
}

.onTop p {
  text-align: center;
  margin: auto !important;
}

.fa-bars {
  color: #000;
}

.fa-items {
  color: #000;
}

.overlayclass {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1070;
}

@media screen and (max-width: 1024px) {
  .StartPage {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 750px;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .form {
    width: 100%;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 10%;
    background: white;
    padding: 25px;
    box-shadow: 6px 6px 10px 2px #00000075;
    border-radius: 15px;
  }

  .password-icon-login {
    position: relative;
    left: 25vw;
    bottom: 3vh;
    cursor: pointer;
  }

  .fa-bars {
    color: #000;
  }

  .fa-items {
    color: #000;
  }
}

.myCookiesdecBtn {
  border-bottom-left-radius: 1.2rem;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  color: #464646;
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
  min-width: 50px;
  min-height: 2rem;
  text-align: center;
  display: block;
  margin-right: auto;
  outline: none;
}

.myCookiesdecBtn:focus {
  outline: none;
  border: 1px solid #d9d9d9;
}

.labelColor {
  color: #ffffff;
}

/* Folder css */
.myFolderWrapper {
  display: flex;
  align-items: flex-start;
  margin: 0px 20px 0px 0px;
  flex-wrap: wrap;
}

input[type="checkbox"]:not(:checked) + .viewSlider {
  background-color: #00ff42;
}

.folderCreated {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: calc(100% / 5);
  margin: 0px 10px;
}

.folderCreatedComp {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% / 10);
  margin: 0px 10px;
}
.filecomp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.FolderWrapperList {
  flex-direction: column;
}
.folderView {
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #77767613;
}
.folderView .folderLabel{
  margin-left: 10px;
  flex-grow: 1;
}
.folderView p{
  cursor: pointer;
}
.fileView {
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #79797910;
}
.FileDate {
  display: block;
  margin-left: auto;
  flex-grow: 0; 
}
.fileView .filecomp {
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.fileView .filecomp .folderLabel{
  margin-left: 24px;
}
.fileView .filecomp .folderLabel p{
  cursor: pointer;
}

.nameError {
  margin-left: 18rem;
  color: red;
}

.folderColor {
  font-size: 50px;
  color: #fccc77;
  cursor: pointer;
}

.folderActive {
  font-size: 50px;
  color: #0000ff;
  cursor: pointer;
}

.folderLabel {
  display: flex;
  max-height: 50px;
  overflow: hidden;
}

.folderDetailsWrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.folderDetails {
  display: flex;
  width: 100%;
  flex-direction: column;
}

/* tp all query css  */
.nav-pills .nav-item {
  width: 20%;
  text-align: center;
  background: #8c8c8c !important;
  border-radius: 25px;
  height: fit-content;
}

.declinedPayment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table tbody tr td {
  color: #464646;
}

.tabHover {
  transition: all 0.9s;
}

.tabHoverLink {
  transition: all 0.9s;
  font-weight: 700;
}

.tabHover:hover {
  text-decoration: underline;
  color: #0071ce;

  cursor: pointer;
}

.tabHoverLink:hover {
  text-decoration: underline;
  color: #0071ce;

  cursor: pointer;
}

.tabHoverLinksubMenu {
  color: #464646;
  height: 40px;

  list-style: none;
  text-align: center;
  z-index: 10;
  margin: auto;
}

.headerHover {
  color: rgb(61, 71, 117);
  height: 40px;
  font-weight: 700;
  list-style: none;
  text-align: center;
  margin: auto;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;
}

.headerHover a {
  color: rgb(61, 71, 117);
}

.tabHoverLinksubMenu ul {
  list-style: none;
}

.tabHoverLinksubMenu:hover,
.tabHoverLink:hover {
  color: #0071ce !important;
  height: 40px;
  cursor: pointer;
  list-style: none;
}

/* Client Header option css */
.tableCellStyle {
  font-size: 16px;
  line-height: 1.2rem;
}

#playVideo {
  position: absolute;
  top: 25%;
  left: 40%;

  z-index: 10;
}

.galleryBox {
  display: flex;
  max-height: 240px;
  min-height: 200px;
  height: 100%;
  width: calc(100% / 4 - 30px);
  margin: 5px;
  position: relative;
  border-radius: 10px;
  flex-flow: column nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.galleryBox a {
  width: 100%;
  height: 100%;
  min-height: 180px;
  max-height: 180px;
}

.galleryBox a img {
  display: block;
  margin: auto auto auto 18px;
  max-width: 100%;
  max-height: 100%;
  width: fit-content;
  height: auto;
}

.galleryBox h4 {
  max-height: 24px;
  overflow-y: hidden;
}

.delIcon {
  width: 100%;
}

.delIcon span i {
  float: left;
}

#artContent2 {
  display: flex;
  max-width: 700px;
  width: 100%;
  word-break: break-word;
}

#artContent {
  display: flex;
  width: 100%;
  word-break: break-word;
}

#artContent p {
  margin: 0px;
}

#artContent table,
#artContent tr,
#artContent td {
  border: 1px solid #000;
}

#artContent td {
  padding: 10px 15px;
}

.image-gallery-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image-gallery {
  display: flex;
  justify-content: center;
}

.image-gallery-image .image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
  max-height: 59px;
  height: 100%;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  max-height: 70px;
  height: 100%;
  line-height: 0;
}

.image-gallery-slide
  .image-gallery-image:not(.fullscreen .image-gallery-image) {
  width: 100%;
  object-fit: cover;
}

/* dashboard css */
.query_thead > tr > th,
.proposal_thead > tr > th,
.proposal_thead3 > tr > th,
.assignment_thead > tr > th,
.assignment_thead2 > tr > th,
.payment_thead > tr > th,
.payment_thead2 > tr > th {
  color: #fff;
}

.overlayStyleLogin {
  display: flex;
  width: 100vw;
  min-height: 900px;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
}

.loginHeading {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  flex-wrap: wrap;
}

.CookieConsent {
  max-width: 600px;
  border-radius: 5px;
  padding: 20px 30px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate3d(-50%, -50%, 9999990px);
  width: 95%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  z-index: 99999;
}

/* navbar css */

.notification {
  color: #4b4646;
}

.notification .badge {
  position: absolute;
  display: flex;
  bottom: 25px;
  left: 25px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 25px;
  background-color: #464b4b;
  color: white;
  padding: 10px;
}

.changePassword .dropdown-item:active {
  background-color: rgb(61, 71, 117) !important;
  color: #fff !important;
}

.nameHeading,
.inboxTitle {
  color: #4b4646;
  font-weight: 600;
  display: block;
  margin: auto;
}

.sidebarIcons {
  display: flex;
  max-width: 40px;
  max-height: 40px;
  padding: 8px;
}

.main-menu.menu-light .navigation > li.hover > a {
  color: #0071ce;
}

/* image gallry boxContainer */
.imgBox {
  display: flex;
  width: calc(100% / 5 - 10px);
  max-height: 150px;
  height: 100%;
  margin: 10px;
}

.image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.img-responsive {
  display: flex;
  width: 100%;
}

/*  header css */
.clientSubMenu li:nth-of-type(2) {
  width: 136px;
}

.clientSubMenu li:nth-of-type(2) div {
  justify-content: center !important;
}

.clientSubMenu li:nth-of-type(7) {
  width: 135px;
}

/* Serach button */
.cmssearchBtn {
  display: flex;
  max-width: 300px;
  justify-content: center;
  align-items: flex-end;
  margin-left: auto;
  padding: 10px;
}

.bredcrubmWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.bredcrubmWrapperarticle {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.emailSelectStyle {
  display: flex;
  max-height: 300px;

  height: 100%;

  overflow: auto;
}

/* date and time picker */
.ant-picker-time-panel-column:not(:first-child) {
  display: none;
}

.picker-datetime-panel .ant-picker-time-panel .ant-picker-header {
  display: none;
}

.ant-picker-now {
  display: none !important;
}

.ant-picker {
  padding: 8px !important;
  border-radius: 4px !important;
  border: 1px solid #ccc !important;
}

.generateTemplate {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.my-fieldsettemplate {
  border: 1px solid #000;
  padding: 10px;
}

.emailerBtn {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1.7rem 0rem;
}

/* emailer modal */
.modal-dialog:has(.modalShowHtml) {
  max-width: 585px !important;
}

.modalShowHtml tbody tr td:has(p) {
  min-height: 59px;
  padding-left: 10px;
  padding-right: 10px;
}

footer a:hover {
  color: #0071ce !important;
}

.twitterLink {
  background-color: rgba(0, 0, 0, 0.6);
}

.nexIconCss {
  display: flex;
  background: whitesmoke;
  padding: 5px;
}

#messageBody table thead {
  background-color: #b0bec5;
}

.navButton {
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  color: #0071ce;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  justify-content: center;
  line-height: 1;
  width: 50px;
  border-radius: 32px;
  min-height: 2rem;
  overflow: hidden;
  padding: 0.2rem 0.2rem;
  position: relative;
  text-decoration: none;
  transform: all 0.3s;
  vertical-align: middle;
}

/* .navButton:focus{
border-color: #0071CE;
border: 0.5px;
} */
.customPagination {
  display: flex;
  width: 100%;
  align-items: center;
  white-space: nowrap;
}

.customPagination {
  display: flex;
  width: 100%;
  align-items: center;
}

.customPaginationSpan {
  width: 40%;
}

.navButtonSelectDiv {
  display: flex;
  max-width: 75px;
  width: 100%;
  margin: 0px 10px;
}

.navButtonSelectDiv select {
  padding-left: 0px;
  padding-right: 0px;
}

.navButtonSelectDiv option {
  text-align: center;
}

th.sortable::after {
  font-family: FontAwesome;
  content: "\f0dc";
  position: absolute;
  right: 8px;
  color: #fff;
  display: none;
}

.filterActive {
  font-weight: 1000;
  font-size: 600;
  font-size: 18px;
}
.logBtn {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 5px;
}
.errorSpan {
  color: red;
  margin-left: 5px;
}
.subcatCom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sub {
  display: flex;
  flex-wrap: wrap;
}
.subcheck label {
  margin: 0 5px;
}
.subcheck {
  flex: 0 0 calc(20% - 10px);
  margin: 2px;
  padding: 10px 0px;
  box-sizing: border-box;
}
/* .subcheck label{
  
} */
.detailSpan {
  font-weight: bold;
}
.detailDiv {
  font-weight: bold;
  margin: 0px 10px;
}
.ComplianceHeader {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.ComplianceHeaderSpan {
  font-size: 15px;
  margin: auto 5% auto 0px;
}
.compPrime {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.compPrime {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  margin-bottom: 14px;
}
/* .compPrimeDet{
  margin-right: 240px;
} */
.subClientdiv {
  margin-bottom: 1rem;
}
.MasterDashBorderGrid{
  width: 100%;
}