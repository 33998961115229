.ck-editor__editable_inline {
  min-height: 150px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.primeCrifSwitch{
  margin: 0px 0px 0px 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.rightAlign {
  display: flex;
  margin-left: auto;
}
.galleryContainer {
  display: flex;
  width: 80vw;
  overflow: scroll;

  flex-wrap: wrap;
}

.galleryBoxvideo {
  display: flex;

  height: 200px;
  width: calc(100% / 5 - 10px);
  margin: 5px;

  border-radius: 10px;
  flex-direction: column;
}
.delIcon {
  display: flex;
  padding: 2px 15px;
  justify-content: space-between;
}
.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #fff;
}

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
